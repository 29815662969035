import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Markdown from 'react-markdown';
import YouTube from 'react-youtube';
import {
  Box,
  Typography,
  Link as MuiLink,
  Button,
  ButtonBase,
  Slider,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';

import { withLayout } from '../components/Layout';
import Seo from '../components/Seo';
import MarkdownHeading from '../components/MarkdownHeading';
import Prose from '../components/Prose';
import ShareButtons from '../components/ShareButtons';

const useQuestionStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.grey[600],
    paddingLeft: theme.spacing(2),
    borderLeft: `3px solid ${theme.palette.grey[300]}`,
  },
}));

const useMobileWatchStyles = makeStyles((theme) => ({
  root: {
    marginLeft: -1 * theme.spacing(2),
    marginRight: -1 * theme.spacing(2),
    borderTop: '#eee 1px solid',
    borderBottom: '#eee 1px solid',
    backgroundColor: '#fff',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },

    '& img': {
      display: 'block',
      width: '85px',
      marginTop: -1,
      marginBottom: -1,
    },
  },
  text: {
    flex: 1,
    padding: theme.spacing(2),
  },
  icon: ({ open }) => ({
    transform: open ? 'rotate(-180deg)' : 'none',
    transition: 'transform 0.25s',
  }),
}));

const useVideoStyles = makeStyles((theme) => ({
  root: ({ open }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: open ? 217 : 0,
    marginLeft: -1 * theme.spacing(2),
    marginRight: -1 * theme.spacing(2),
    marginBottom: theme.spacing(5),
    backgroundColor: '#000',
    overflow: 'hidden',
    transition: 'height 0.25s',
    [theme.breakpoints.up('sm')]: {
      float: 'right',
      height: 217,
      marginLeft: theme.spacing(4),
      marginRight: 0,
      marginBottom: theme.spacing(2),
    },
  }),
  player: {
    display: 'flex',
    justifyContent: 'center',
    height: 183,
    [theme.breakpoints.up('sm')]: {
      width: 325,
    },
  },
  controls: {
    background: theme.palette.background.default,
  },
}));

const markdownRenderers = {
  heading: MarkdownHeading,
  link: MuiLink,
};

const Page = ({ pageContext }) => {
  const { answer } = pageContext;

  const [mobileWatchOpen, setMobileWatchOpen] = useState(false);
  const [player, setPlayer] = useState(null);
  const [clock, setClock] = useState(0);

  const questionClasses = useQuestionStyles();
  const videoClasses = useVideoStyles({ open: mobileWatchOpen });
  const mobileWatchClasses = useMobileWatchStyles({ open: mobileWatchOpen });

  useEffect(() => {
    // this updates state on an interval so the video slider can change with playback
    const interval = setInterval(() => setClock(Date.now()), 100);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Seo
        type="article"
        cardType="summary_large_image"
        title={answer.title}
        description={`Answered by ${answer.speaker.name}.`}
        image={answer.unfurl}
      />

      <Box mb={5}>
        <Typography variant="h1">{answer.title}</Typography>
      </Box>

      <div className={mobileWatchClasses.root}>
        <ButtonBase
          component="div"
          style={{ display: 'flex' }}
          onClick={() => setMobileWatchOpen(!mobileWatchOpen)}
        >
          <img
            src={`https://i.ytimg.com/vi/${answer.youtubeId}/hqdefault.jpg`}
            alt={answer.title}
          />
          <div className={mobileWatchClasses.text}>Watch Answer On Video</div>
          <ExpandMoreIcon className={mobileWatchClasses.icon} />
        </ButtonBase>
      </div>

      <div className={videoClasses.root}>
        <div className={videoClasses.player}>
          <YouTube
            videoId={answer.youtubeId}
            onReady={({ target }) => setPlayer(target)}
            opts={{
              width: '325',
              height: '183',
              playerVars: {
                // https://developers.google.com/youtube/player_parameters
                playsinline: 1,
                modestbranding: 1,
                controls: 0,
                rel: 0,
                start: answer.start,
                end: answer.end,
              },
            }}
          />
        </div>
        <div className={videoClasses.controls}>
          <Slider
            min={0}
            max={answer.end - answer.start}
            value={
              clock > -1 && player ? player.getCurrentTime() - answer.start : 0
            }
            onChange={(event, time) =>
              player && player.seekTo(answer.start + time)
            }
          />
        </div>
      </div>

      <Box mb={4}>
        <Typography variant="h4" component="h2" classes={questionClasses}>
          {answer.question}
        </Typography>
      </Box>

      <Box mb={4}>
        <Prose>
          <Markdown components={markdownRenderers}>
            {answer.transcription}
          </Markdown>
        </Prose>
      </Box>

      <Box mb={6}>
        <Typography variant="subtitle1">
          Answered by {answer.speaker.name}
        </Typography>
      </Box>

      <Box mb={8}>
        <ShareButtons title={answer.title} align="center" />
      </Box>

      <Box textAlign="center">
        <Button color="primary" component={Link} to="/">
          Browse All Questions &raquo;
        </Button>
      </Box>
    </>
  );
};

Page.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default withLayout(Page, { container: 'md' });
