import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const MarkdownHeading = ({ level, children }) => {
  switch (level) {
    case 1:
      return <Typography variant="h2">{children}</Typography>;
    case 2:
      return <Typography variant="h3">{children}</Typography>;
    case 3:
      return <Typography variant="h4">{children}</Typography>;
    default:
      return <Typography variant="h5">{children}</Typography>;
  }
};

MarkdownHeading.propTypes = {
  level: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default MarkdownHeading;
